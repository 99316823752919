import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import {
  CButton,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CTable,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNavLink,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CCallout,
  CImage,
  CLoadingButton,
} from '@coreui/react-pro'
import QRCode from 'qrcode.react'
import * as cusUtil from 'src/viewsCommon/CusUtil'
import * as cusUtil_passit from 'src/viewsCus/_company/common/CusUtil_passit'
import CusResultModal from 'src/viewsCommon/CusResultModal'

const DoorOpenResultModal_passit = forwardRef((param, ref) => {
  useImperativeHandle(ref, () => ({
    childFetchData: (centerDoorUid, centerUid) => {
      fetchData(centerDoorUid, centerUid)
    },
  }))

  const navigate = useNavigate()

  const [visible, setVisible] = useState(false)
  const [data, setData] = useState({})
  const [dataCenterName, setDataCenterName] = useState(param.centerName)

  const fetchData = async (centerDoorUid, centerUid) => {
    const url =
      '/cube_dev_local/api/user/center/centerDoor/auth/my/' + centerDoorUid + '/' + centerUid
    console.log('door open url: ', url)
    cusUtil
      .axiosGet(url)
      .then((response) => {
        console.log(response, response.data.detail)
        setData(response.data)
        setVisible(!visible)

        setDataCenterName(response.data.detail)
      })
      .catch((error) => {
        console.error('centerDoor open fail: ', error)
      })
    setVisible(!visible)
  }

  const handleCloseModal = async () => {
    console.log('dataCenterName', dataCenterName)
    setVisible(false)
    param.closeModal(dataCenterName)
  }

  return (
    <>
      {(() => {
        if (param.centerDoorUid && param.centerUid && param.centerName) {
          return (
            <>
              <CButton
                color="danger"
                className="text-white border-radius-0"
                onClick={() => fetchData(param.centerDoorUid, param.centerUid)}
                style={cusUtil_passit.styleBtnRed}
              >
                {param.title}
              </CButton>
            </>
          )
        }
      })()}
      <CModal fullscreen backdrop="static" visible={visible} onClose={() => setVisible(false)}>
        {/* <CModalHeader>
          <CModalTitle>상세정보</CModalTitle>
        </CModalHeader> */}
        {/* <CModalBody> */}
        <div style={cusUtil_passit.styleDivValign}>
          <div style={cusUtil_passit.styleDivValignTop}>
            <CRow>
              <CCol>
                <CImage fluid src="/images/pas/ic_logo_white.png" className="pt-5" />
              </CCol>
            </CRow>
          </div>

          <div style={cusUtil_passit.styleDivValignMiddle}>
            <CRow className="pt-20">
              <CCol>
                <CRow>
                  {(() => {
                    if (data && data.resultYne == 'Y') {
                      return (
                        <CCol className="pt-8" style={cusUtil_passit.styleFontNormalXLarge}>
                          <span style={cusUtil_passit.styleFontBold}>{dataCenterName}</span> 점
                          출입문이 열립니다.
                          <div>만약 계속 열리지 않을 경우 </div>
                          <div>재시도 하거나 연락 바랍니다. </div>
                          <div>1855-0137 </div>
                          <div>&nbsp;</div>
                          <span style={cusUtil_passit.styleFontBoldRed}>
                            만약 미성년자가 출입 제한시간에{' '}
                          </span>
                          <span style={cusUtil_passit.styleFontBoldRed}>
                            출입을 시도할 경우 관계법에 의해{' '}
                          </span>
                          <span style={cusUtil_passit.styleFontBoldRed}>
                            처벌받을 수 있습니다.{' '}
                          </span>
                        </CCol>
                      )
                    } else if (data && data.resultMsg == 'fail_age') {
                      return (
                        <CCol className="pt-8" style={cusUtil_passit.styleFontNormalXLarge}>
                          <span style={cusUtil_passit.styleFontBold}>{dataCenterName}</span> 점
                          출입문 열기에 실패하였습니다.
                          <div>만약 계속 열리지 않을 경우 </div>
                          <div>재시도 하거나 연락 바랍니다. </div>
                          <div>1855-0137 </div>
                          <div>&nbsp;</div>
                          <span style={cusUtil_passit.styleFontBoldRed}>
                            만약 미성년자가 출입 제한시간에{' '}
                          </span>
                          <span style={cusUtil_passit.styleFontBoldRed}>
                            출입을 시도할 경우 관계법에 의해{' '}
                          </span>
                          <span style={cusUtil_passit.styleFontBoldRed}>
                            처벌받을 수 있습니다.{' '}
                          </span>
                        </CCol>
                      )
                    } else {
                      return (
                        <CCol className="pt-8" style={cusUtil_passit.styleFontNormalXLarge}>
                          <span style={cusUtil_passit.styleFontBold}>{dataCenterName}</span> 점
                          출입문 열기에 실패하였습니다.
                          <div>만약 계속 열리지 않을 경우 </div>
                          <div>재시도 하거나 연락 바랍니다. </div>
                          <div>1855-0137 </div>
                          <div>&nbsp;</div>
                          <span style={cusUtil_passit.styleFontBoldRed}>
                            만약 미성년자가 출입 제한시간에{' '}
                          </span>
                          <span style={cusUtil_passit.styleFontBoldRed}>
                            출입을 시도할 경우 관계법에 의해{' '}
                          </span>
                          <span style={cusUtil_passit.styleFontBoldRed}>
                            처벌받을 수 있습니다.{' '}
                          </span>
                        </CCol>
                      )
                    }
                  })()}
                </CRow>
              </CCol>
            </CRow>
          </div>

          <div style={cusUtil_passit.styleDivValignBottom}>
            <CRow>
              <CCol>
                <CRow>
                  <CCol className="d-grid my-3">
                    <CButton
                      // loading={btnLoignState}
                      // size="sm"
                      color="danger"
                      className="text-white border-radius-0"
                      onClick={handleCloseModal}
                      // onClick={() => setVisible(false)}
                      style={cusUtil_passit.styleBtnRed}
                    >
                      확인
                    </CButton>
                  </CCol>
                </CRow>
                <div className="mb-4" style={cusUtil_passit.styleFontNormalSmall5}>
                  Copyright Tobesmart., Ltd. All right reserved.
                </div>
              </CCol>
            </CRow>
          </div>
        </div>
        {/* </CModalBody> */}
      </CModal>
    </>
  )
})

export default DoorOpenResultModal_passit
