import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
// import CusApi from 'src/viewsCommon/CusApi'
import * as cusUrlUtil from 'src/viewsCommon/CusUrlUtil'

// 쿠키에 값을 저장하는 함수
export function setCookie(name, value, days) {
  const expirationDate = new Date()
  expirationDate.setDate(expirationDate.getDate() + days)

  const cookieValue =
    encodeURIComponent(value) + '; expires=' + expirationDate.toUTCString() + '; path=/'
  document.cookie = name + '=' + cookieValue
}

// 쿠키에서 값을 가져오는 함수
export function getCookie(name) {
  const cookieName = name + '='
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim()
    if (cookie.indexOf(cookieName) === 0) {
      return decodeURIComponent(cookie.substring(cookieName.length, cookie.length))
    }
  }

  return null
}

// 호스트 또는 env 파일 확인해서 cid 가져오기
export function getCid() {
  // const currentURL = window.location.hostname
  // const currentURL = 'passit.'
  // console.log('currentURL', currentURL)
  // return currentURL

  // return process.env.REACT_APP_COMPANY_ID

  return cusUrlUtil.getCid()
}

// apiUrl 을 가져오는 함수
export function getTargetUrl() {
  return cusUrlUtil.getTargetUrl()
}

// 현재 날짜 문자열 반환 (8자리)
export function getCurrentDtStr() {
  const currentDate = new Date()

  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')

  return `${year}${month}${day}`
}

// 현재 날짜 plus minus 문자열 반환 (8자리)
export function getCurrentPlusMinusDtStr(plusMinusDay) {
  const currentDate = new Date()

  // 날짜 조정
  currentDate.setDate(currentDate.getDate() + plusMinusDay)

  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')

  return `${year}${month}${day}`
}

// 현재 날짜시간 문자열 반환 (12자리)
export function getCurrentDtTimeStr() {
  const currentDate = new Date()

  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')
  const hours = currentDate.getHours().toString().padStart(2, '0')
  const minutes = currentDate.getMinutes().toString().padStart(2, '0')

  return `${year}${month}${day}${hours}${minutes}`
}

// 현재 날짜 문자열 반환 (한글)
export function getCurrentDtKorStr() {
  const currentDate = new Date()

  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')

  return `${year}년 ${month}월 ${day}일`
}

// 현재 날짜 문자열 반환 (Hyphen)
export function getCurrentDtHyphenStr() {
  const currentDate = new Date()

  const year = currentDate.getFullYear()
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
  const day = currentDate.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

// 날짜 문자열 변환
export function getDtStr(dt) {
  if (!dt) {
    return '' // 빈 값일 경우 빈 문자열 반환
  }

  const numericDate = dt

  const year = numericDate.slice(0, 4)
  const month = numericDate.slice(4, 6)
  const day = numericDate.slice(6, 8)
  const hour = numericDate.slice(8, 10)
  const minute = numericDate.slice(10, 12)
  const second = numericDate.slice(12, 14)

  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second}`
  return formattedDate
}
export function getSimpleDtStr(dt) {
  if (!dt) {
    return '' // 빈 값일 경우 빈 문자열 반환
  }

  const numericDate = dt

  const year = numericDate.slice(0, 4)
  const month = numericDate.slice(4, 6)
  const day = numericDate.slice(6, 8)
  const hour = numericDate.slice(8, 10)
  const minute = numericDate.slice(10, 12)
  const second = numericDate.slice(12, 14)

  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`
  return formattedDate
}
export function getSimpleDtStr10(dt) {
  if (!dt) {
    return '' // 빈 값일 경우 빈 문자열 반환
  }

  const numericDate = dt

  const year = numericDate.slice(2, 4)
  const month = numericDate.slice(4, 6)
  const day = numericDate.slice(6, 8)
  const hour = numericDate.slice(8, 10)
  const minute = numericDate.slice(10, 12)
  const second = numericDate.slice(12, 14)

  const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`
  return formattedDate
}
export function getDateStr(dt) {
  if (!dt) {
    return '' // 빈 값일 경우 빈 문자열 반환
  }

  const numericDate = dt

  let year
  let month
  let day

  if (dt.length === 8 || dt.length === 14) {
    year = numericDate.slice(0, 4)
    month = numericDate.slice(4, 6)
    day = numericDate.slice(6, 8)
  } else if (dt.length === 6) {
    year = '20' + numericDate.slice(0, 2)
    month = numericDate.slice(2, 4)
    day = numericDate.slice(4, 6)
  }

  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}
export function getDateKorStr(dt) {
  if (!dt) {
    return '' // 빈 값일 경우 빈 문자열 반환
  }

  const numericDate = dt

  let year
  let month
  let day

  if (dt.length === 8 || dt.length === 14) {
    year = numericDate.slice(0, 4)
    month = numericDate.slice(4, 6)
    day = numericDate.slice(6, 8)
  } else if (dt.length === 6) {
    year = '20' + numericDate.slice(0, 2)
    month = numericDate.slice(2, 4)
    day = numericDate.slice(4, 6)
  }

  const formattedDate = `${year}년 ${month}월 ${day}일`
  return formattedDate
}

// 날짜문자열을 date 로 반환
export function parseDateFrom8DigitNumber(dateStr) {
  const dateString = dateStr.toString()
  const year = parseInt(dateString.slice(0, 4), 10)
  const month = parseInt(dateString.slice(4, 6), 10) - 1 // 월은 0부터 시작
  const day = parseInt(dateString.slice(6, 8), 10)
  return new Date(year, month, day)
}

// 년일수 조회 (이자계산시 사용)
export function getDaysInYearBasedOnRepaymentDate(dateStr) {
  const date = parseDateFrom8DigitNumber(dateStr)

  const year = date.getFullYear()
  const isLeapYear = new Date(year, 1, 29).getMonth() === 1

  if (!isLeapYear) {
    return 365
  }

  const repaymentMonth = date.getMonth()
  const repaymentDay = date.getDate()

  // 윤년이지만 2월 29일 이전이라면 365일로 계산
  if (repaymentMonth < 1 || (repaymentMonth === 1 && repaymentDay < 29)) {
    return 365
  }

  return 366
}

export function getTimeStr(time) {
  if (!time) {
    return '' // 빈 값일 경우 빈 문자열 반환
  }

  const numericDate = time

  const hour = numericDate.slice(0, 2)
  const minute = numericDate.slice(2, 4)

  const formattedDate = `${hour}:${minute}`
  return formattedDate
}

// CDatePicker 에 들어가는 포맷으로 변경
export function getDateStrInput(dt) {
  if (!dt) {
    return '' // 빈 값일 경우 빈 문자열 반환
  }

  const numericDate = dt

  let year
  let month
  let day

  if (dt.length === 8 || dt.length === 14) {
    year = numericDate.slice(0, 4)
    month = numericDate.slice(4, 6)
    day = numericDate.slice(6, 8)
  } else if (dt.length === 6) {
    year = '20' + numericDate.slice(0, 2)
    month = numericDate.slice(2, 4)
    day = numericDate.slice(4, 6)
  }

  const formattedDate = `${year}/${month}/${day}`
  return formattedDate
}
export function getDateTimeStrInput(dt) {
  if (!dt) {
    return '' // 빈 값일 경우 빈 문자열 반환
  }

  const numericDate = dt

  let year
  let month
  let day
  let hour
  let min

  if (dt.length === 14) {
    year = numericDate.slice(0, 4)
    month = numericDate.slice(4, 6)
    day = numericDate.slice(6, 8)
    hour = numericDate.slice(8, 10)
    min = numericDate.slice(10, 12)
  }

  const formattedDate = `${year}/${month}/${day} ${hour}:${min}`
  return formattedDate
}

// 요일 문자열 변환
export function convertToDays(inputStr) {
  const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
  let resultStr = ''

  for (let i = 0; i < inputStr.length; i++) {
    const digit = parseInt(inputStr.charAt(i))
    if (!isNaN(digit) && digit >= 0 && digit < daysOfWeek.length) {
      if (digit === 1) {
        if (resultStr !== '') {
          // 첫 번째 요일이 아닌 경우 콤마 추가
          resultStr += ','
        }
        resultStr += daysOfWeek[i]
      }
    } else {
      // 유효하지 않은 숫자는 무시
    }
  }

  return resultStr
}

// 가격 문자열 변환
export function getPriceStr(price) {
  if (!price) {
    return '' // 빈 값일 경우 빈 문자열 반환
  }

  // price가 문자열인 경우 숫자로 변환
  const numericPrice = typeof price === 'string' ? parseFloat(price) : price

  // 숫자를 통화 포맷으로 변환
  return numericPrice.toLocaleString('ko-KR', {
    style: 'currency',
    currency: 'KRW', // 원하는 통화 코드를 지정하세요
    minimumFractionDigits: 0, // 소수점 아래 최소 자릿수 설정
  })
}

export function getNumberInKorean(number) {
  if (!number) {
    return ''
  }
  number = removeCommas(number)

  const han1 = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구']
  const han2 = ['', '십 ', '백 ', '천 ']
  const han3 = ['', '만 ', '억 ', '조 ', '경 ']
  let result = ''
  const len = number.length
  let nowInt = 0
  let hasHan3 = false

  // console.log('getNumberInKorean', number, number.length)

  for (let i = len; i > 0; i--) {
    nowInt = parseInt(number.substring(len - i, len - i + 1), 10)
    const han2Pick = (i - 1) % 4
    if (nowInt > 0) {
      result += han1[nowInt] + han2[han2Pick]
      if (han2Pick > 0) {
        hasHan3 = false
      }
    }
    if (!hasHan3 && han2Pick === 0) {
      result += han3[Math.floor((i - 1) / 4)]
      hasHan3 = true
    }
  }
  return result
}

export function getNumberInKoreanOri(number) {
  if (!number) {
    return ''
  }
  number = removeCommas(number)

  const koreanNumbers = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구']
  const unit = [
    '',
    '십 ',
    '백 ',
    '천 ',
    '만 ',
    '십만 ',
    '백만 ',
    '천만 ',
    '억 ',
    '십억 ',
    '백억 ',
    '천억 ',
    '조 ',
  ]
  let result = ''

  const numberStr = number.toString()
  const length = numberStr.length

  for (let i = 0; i < length; i++) {
    const digit = parseInt(numberStr.charAt(i), 10)

    // 숫자가 0이면 무시하고 다음 자리수로 넘어감
    if (digit === 0) {
      continue
    }

    result += koreanNumbers[digit] + unit[length - i - 1]
  }

  return result
}

// 숫자에 콤마 넣어서 변환
export function numberWithCommas(number) {
  if (number === undefined || number === null) {
    return ''
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// 콤마를 빼고 변환
export function removeCommas(input) {
  if (typeof input === 'string') {
    return input.replace(/,/g, '')
  } else if (typeof input === 'number') {
    return input.toString().replace(/,/g, '')
  }
  // return input.replace(/,/g, '')
}

// 전화번호 문자열 변환
export function getPhoneNumberStr(phoneNumber) {
  // Check if phoneNumber is undefined or null
  if (phoneNumber === undefined || phoneNumber === null) {
    return ''
  }

  // Remove any non-digit characters from the phone number
  const numericPhoneNumber = phoneNumber.replace(/\D/g, '')

  if (numericPhoneNumber.length === 12 && numericPhoneNumber.startsWith('82')) {
    return `0${numericPhoneNumber.slice(2, 4)}-${numericPhoneNumber.slice(
      4,
      8,
    )}-${numericPhoneNumber.slice(8)}`
  } else if (numericPhoneNumber.length === 0) {
    return phoneNumber
  } else {
    return `${numericPhoneNumber.slice(0, 3)}-${numericPhoneNumber.slice(
      3,
      7,
    )}-${numericPhoneNumber.slice(7)}`
  }
}

// 전화번호여부 (-없이 숫자만 체크)
export function getPhoneNumberCheck(number) {
  if (/^(01[016789]\d{7,8})|(0[2-9]{1}[0-9]\d{7,8})$/.test(number) && /^\d+$/.test(number)) {
    return true
  } else {
    return false
  }
}

// 숫자 문자열 변환 (숫자 아닌건 제외)
export function getNumberStr(number) {
  const numericPhoneNumber = number.replace(/\D/g, '')
  return numericPhoneNumber
}

// 숫자여부 (콤마제거)
export function getNumberCheckRemoveCommas(number) {
  if (/^\d*$/.test(removeCommas(number))) {
    return true
  } else {
    return false
  }
}

// 숫자여부 (소수점 포함)
export function getNumberCheckWithDot(number) {
  if (/^\d*\.?\d*$/.test(number)) {
    return true
  } else {
    return false
  }
}

// 날짜검색 문자열 변환
export function getCustomDateRanges() {
  const customDateRanges = {
    '오늘 ': [new Date(), new Date()],
    '어제 ': [
      new Date(new Date().setDate(new Date().getDate() - 1)),
      new Date(new Date().setDate(new Date().getDate() - 1)),
    ],
    '최근 7일': [new Date(new Date().setDate(new Date().getDate() - 6)), new Date(new Date())],
    '최근 30일': [new Date(new Date().setDate(new Date().getDate() - 29)), new Date(new Date())],
    '이번 달': [
      new Date(new Date().setDate(1)),
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    ],
    '지난 달': [
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
      new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    ],
  }

  return customDateRanges
}

// 문자열 자르기
export function getTruncateStr(str, maxLength) {
  return str.length > maxLength ? str.slice(0, maxLength) + '...' : str
}

// Haversine 공식을 사용하여 두 좌표 간의 거리 계산
export function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371 // Earth's radius in kilometers
  const dLat = degreesToRadians(lat2 - lat1)
  const dLon = degreesToRadians(lon2 - lon1)
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(lat1)) *
      Math.cos(degreesToRadians(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return R * c // Distance in kilometers
}

export function degreesToRadians(degrees) {
  return degrees * (Math.PI / 180)
}

// 쿠키에서 값을 가져오는 함수
export function setModalOverflowHidden() {
  const timer = setTimeout(() => {
    document.body.style.overflow = 'hidden'
  }, 500)
}

// JSON Pretty 변환
export function getJsonPrettyStr(jsonStr) {
  let jsonDataStr = ''
  try {
    const jsonData = JSON.parse(jsonStr)
    jsonDataStr = JSON.stringify(jsonData, null, 2) // 들여쓰기 2칸
  } catch (error) {}

  return jsonDataStr
}

// 권한을 확인하는 함수
export function checkUserPermission() {
  const token = getJwtToken()
  if (token && token.sub && token.user && token.user.auth) {
    const userName = token.sub
    if (userName == '') {
      return ''
    } else {
      const auth = token.user.auth
      if (auth.includes('11')) {
        return 'root'
      } else if (auth.includes('12')) {
        return 'admin'
      } else if (auth.includes('13')) {
        return 'cadmin'
      } else if (auth.includes('14')) {
        return 'cmanager'
      } else if (auth.includes('21')) {
        return 'user'
      } else if (auth.includes('99')) {
        return 'temp'
      }
      return ''
    }
  } else {
    return ''
  }
}

// 토큰 체크
export function checkJwtToken(config) {
  // console.log('config:', config.url)
  const url = config.url

  //if (url.includes('/api/authenticate')) {
  if (
    url.includes('/api/authenticate') ||
    url.includes('/api/userAuthNum/request') ||
    url.includes('/api/userAuthNum/user/request') ||
    url.includes('/api/file/guest') ||
    url.includes('/api/guest')
  ) {
    return config
  } else {
    // API 요청 전에 토큰 확인
    const jwttoken = getCookie('jwttoken')
    const jwttokenRe = getCookie('jwttokenRe')

    if (jwttoken || jwttokenRe) {
      // 토큰이 존재하는 경우, 유효한 토큰 처리 로직
      // console.log('토큰 jwttoken:', jwttoken)
      // console.log('토큰 jwttokenRe:', jwttokenRe)

      // 토큰 디코딩
      const encodedPayload = jwttoken.split('.')[1]
      const decodedPayload = atob(encodedPayload)
      const payload = JSON.parse(decodedPayload)
      // console.log('payload: ', payload)

      const currentTimestamp = Math.floor(Date.now() / 1000) // 현재 시간
      const expirationTimestamp = payload.exp // 토큰의 만료 시간

      if (expirationTimestamp < currentTimestamp) {
        if (jwttokenRe) {
          // 토큰 디코딩
          const encodedPayloadRe = jwttokenRe.split('.')[1]
          const decodedPayloadRe = atob(encodedPayloadRe)
          const payloadRe = JSON.parse(decodedPayloadRe)
          // console.log('payloadRe: ', payloadRe)

          const expirationTimestampRe = payloadRe.exp // 토큰의 만료 시간

          if (expirationTimestampRe < currentTimestamp) {
            // 만료된 경우에 대한 처리를 수행
            console.log('expire tokenRe', getCookie('isKiosk'))
            if (getCookie('isKiosk') == 'y') {
              window.location.href = '/#/kioskMain'
            } else {
              window.location.href = '/Login#/login?popup=y'
            }
            return config
          } else {
            // 유효한 경우에 대한 처리를 수행
            console.log('valid tokenRe')

            axiosGet('/cube_dev_local/api/authenticateRe/' + jwttokenRe)
              .then((response) => {
                console.log(response)
                const responseData = response.data

                if (responseData.resultYne == 'Y') {
                  const jwttoken = responseData.jwttoken
                  const jwttokenRe = responseData.jwttokenRe

                  setCookie('jwttoken', jwttoken, 365)
                  setCookie('jwttokenRe', jwttokenRe, 365)

                  // 유효한 경우에 대한 처리를 수행
                  console.log('authenticateRe success')
                  config.headers.Authorization = `${jwttoken}`
                } else {
                  // 만료된 경우에 대한 처리를 수행
                  console.log('expire token', getCookie('isKiosk'))
                  if (getCookie('isKiosk') == 'y') {
                    window.location.href = '/#/kioskMain'
                  } else {
                    window.location.href = '/Login#/login?popup=y'
                  }
                }
                return config
              })
              .catch((error) => {
                console.error('authenticateRe fail: ', error)
                window.location.href = '/Login#/login?popup=y'
              })
          }
        } else {
          // 만료된 경우에 대한 처리를 수행
          console.log('expire token', getCookie('isKiosk'))
          if (getCookie('isKiosk') == 'y') {
            window.location.href = '/#/kioskMain'
          } else {
            window.location.href = '/Login#/login?popup=y'
          }
        }
        return config
      } else {
        // 유효한 경우에 대한 처리를 수행
        console.log('valid token')
        config.headers.Authorization = `${jwttoken}`
        return config
      }
    } else {
      // 토큰이 존재하지 않는 경우, 로그인 필요 로직
      console.log('empty token', getCookie('isKiosk'))
      if (getCookie('isKiosk') == 'y') {
        window.location.href = '/#/kioskMain'
      } else {
        window.location.href = '/Login#/login?popup=y'
      }
      return config
    }
  }
}

// 토큰 구하기
export function getJwtToken() {
  const jwttoken = getCookie('jwttoken')

  if (jwttoken) {
    // 토큰 디코딩
    const encodedPayload = jwttoken.split('.')[1]
    const decodedPayload = atob(encodedPayload)
    const payload = JSON.parse(decodedPayload)

    const currentTimestamp = Math.floor(Date.now() / 1000) // 현재 시간
    const expirationTimestamp = payload.exp // 토큰의 만료 시간

    if (expirationTimestamp < currentTimestamp) {
      console.log('expire token')
      return ''
    } else {
      console.log('valid token')
      return payload
    }
  } else {
    console.log('empty token')
    return ''
  }
}

export function getJwtTokenRe() {
  const jwttoken = getCookie('jwttokenRe')

  if (jwttoken) {
    // 토큰 디코딩
    const encodedPayload = jwttoken.split('.')[1]
    const decodedPayload = atob(encodedPayload)
    const payload = JSON.parse(decodedPayload)

    const currentTimestamp = Math.floor(Date.now() / 1000) // 현재 시간
    const expirationTimestamp = payload.exp // 토큰의 만료 시간

    if (expirationTimestamp < currentTimestamp) {
      console.log('expire tokenRe')
      return ''
    } else {
      console.log('valid tokenRe')
      return payload
    }
  } else {
    console.log('empty tokenRe')
    return ''
  }
}

// api 호출
const cusApi = axios.create()
cusApi.interceptors.request.use(
  (config) => {
    return checkJwtToken(config)
  },
  (error) => {
    return Promise.reject(error)
  },
)

export function axiosGet(url) {
  return cusApi.get(url)
}

export function axiosPost(url, request) {
  return cusApi.post(url, request)
}

export function axiosDelete(url) {
  return cusApi.delete(url)
}

export function axiosPut(url, request) {
  return cusApi.put(url, request)
}

export function axiosPostOrPut(url, request, uid) {
  if (!uid) {
    return cusApi.post(url, request)
  } else {
    return cusApi.put(url + '/' + uid, request)
  }
}

export function axiosDownload(url) {
  return cusApi.get(url, { responseType: 'arraybuffer' })
}

export function axiosUpload(url, request) {
  return cusApi.post(url, request, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function cusfetch(method, url) {
  const fetchConfig = {
    method: `${method}`,
    url: `${url}`,
    headers: {
      Authorization: '',
    },
  }
  // console.log('fetchConfig:', fetchConfig)
  checkJwtToken(fetchConfig)

  return fetch(url, fetchConfig)
}
