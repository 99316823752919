import React, { useEffect, useState, createRef } from 'react'
import {
  CButton,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CTable,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNavLink,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CCallout,
} from '@coreui/react-pro'
import * as cusUtil from 'src/viewsCommon/CusUtil'
import * as cusJsxUtil from 'src/viewsCommon/CusJsxUtil'
import CusResultModal from 'src/viewsCommon/CusResultModal'

const CenterRuleDetailViewModal = (param) => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState({})

  const fetchData = async () => {
    // 검색조건
    let searchConditionUrl = 'ruleCd=' + param.ruleCd
    console.log('list url searchConditionUrl: ', searchConditionUrl)
    searchConditionUrl = 'search=' + encodeURIComponent(searchConditionUrl)

    const url = `/cube_dev_local/api/guest/center/centerRuleList?${searchConditionUrl}`
    console.log('list url: ', url)

    cusUtil
      .axiosGet(url)
      .then((response) => {
        console.log(response)

        if (response.data.total == 0) {
          const tempData = {
            ruleText: '약관 입력중 입니다.',
          }
          setData(tempData)
        } else {
          setData(response.data.list[0])
        }
        setVisible(!visible)
      })
      .catch((error) => {
        console.error('centerRuleList fail: ', error)
      })
  }

  const TableData = () => {
    return (
      <CCol xs={12}>
        <CCard className="mb-4">
          {/* <CCardHeader>
          </CCardHeader> */}
          <CCardBody className="bg-light dark:bg-white dark:bg-opacity-10">
            <CRow className="mb-0">
              {/* <CFormLabel className="col-sm-2 col-form-label">약관내용</CFormLabel> */}
              <div className="col-sm-12">
                {data.ruleHtmlYn == 'y' ? (
                  <cusJsxUtil.renderHTML htmlString={data.ruleText}></cusJsxUtil.renderHTML>
                ) : (
                  <cusJsxUtil.renderWithLineBreaks
                    textString={data.ruleText}
                  ></cusJsxUtil.renderWithLineBreaks>
                )}
              </div>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    )
  }

  var title = ''
  if (param.ruleCd == '01') {
    title = '서비스이용약관'
  } else if (param.ruleCd == '02') {
    title = '개인정보 처리방침'
  } else if (param.ruleCd == '03') {
    title = '위치기반서비스 이용약관'
  } else if (param.ruleCd == '04') {
    title = '마케팅 정보 활용 동의'
  }

  return (
    <>
      {(() => {
        const isButton = param.isButton

        if (isButton === 'n') {
          return (
            <>
              <span role="button" onClick={() => fetchData()}>
                {param.ruleText}
              </span>
            </>
          )
        } else {
          return (
            <>
              <CButton
                color="success"
                size="sm"
                className="py-0"
                variant="ghost"
                onClick={() => fetchData()}
              >
                {param.ruleText}
              </CButton>
            </>
          )
        }
      })()}

      <CModal fullscreen backdrop="static" visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <TableData />

          <CContainer>
            <CRow>
              <CCol align="center">
                <CButton
                  color="dark"
                  size="sm"
                  className="mx-1 text-white"
                  onClick={() => setVisible(false)}
                >
                  닫기
                </CButton>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
      </CModal>
    </>
  )
}

export default CenterRuleDetailViewModal
