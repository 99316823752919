/* eslint-disable react/react-in-jsx-scope */

export const stringToSVG = (param) => {
  const inputData = param.data

  // 입력 문자열을 width * height 그리드로 변환
  const factor = param.factor
  const width = param.width
  const height = param.height
  const grid = []
  for (let i = 0; i < width; i++) {
    const row = []
    for (let j = 0; j < height; j++) {
      // 입력 문자열의 각 문자를 width * height 그리드에 매핑
      const index = i * width + j
      // const color = inputData.charAt(index) === '1' ? 'black' : 'white'
      let color = 'black'
      if (inputData.charAt(index) === '1') {
        color = 'black'
      } else if (inputData.charAt(index) === '0') {
        color = 'white'
      } else if (inputData.charAt(index) === '2') {
        color = 'red'
      }
      row.push(
        <rect
          key={`${i}-${j}`}
          x={j * factor}
          y={i * factor}
          width={factor}
          height={factor}
          fill={color}
        />,
      )
    }
    grid.push(row)
  }

  return (
    <svg width={width * factor} height={height * factor}>
      {grid}
    </svg>
  )
}

export const renderHTML = (param) => {
  return <div dangerouslySetInnerHTML={{ __html: param.htmlString }} />
}

// 줄바꿈 문자를 <br> 태그로 변경
export const renderWithLineBreaks = (param) => {
  const htmlString = param.textString.replace(/\n/g, '<br>')

  return <div dangerouslySetInnerHTML={{ __html: htmlString }} />
}
